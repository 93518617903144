import request from '@/utils/request';

export function QueryTaxList(params) {
  return request({
    url: '/tax_port_alarm/data',
    method: 'get',
    params
  });
}

export function ExportTax(params) {
  return request({
    url: '/tax_port_alarm/export',
    method: 'get',
    responseType: 'blob',
    params
  });
}

export function QueryDeviceList(params) {
  return request({
    url: '/control_alarm/data',
    method: 'get',
    params
  });
}

export function ExportDevice(params) {
  return request({
    url: '/control_alarm/export',
    method: 'get',
    responseType: 'blob',
    params
  });
}

export function QueryTaxChangeList(params) {
  return request({
    url: '/tax_change/data',
    method: 'get',
    params: {
      ...params,
      type: 1
    }
  });
}

export function ExportTaxChange(params) {
  return request({
    url: '/tax_change/export',
    method: 'get',
    responseType: 'blob',
    params: {
      ...params,
      type: 1
    }
  });
}

// 编码器变更
export function QueryEncoderList(params) {
  return request({
    url: '/tax_change/data',
    method: 'get',
    params: {
      ...params,
      type: 2
    }
  });
}

export function ExportEncoder(params) {
  return request({
    url: '/tax_change/export',
    method: 'get',
    responseType: 'blob',
    params: {
      ...params,
      type: 2
    }
  });
}
export function saveChangeCode(params) {
  return request({
    url: '/tax_change/add_form',
    method: 'get',
    params
  });
}

export function QueryDeviceTimeList(params) {
  return request({
    url: '/tax_time_error/data',
    method: 'get',
    params
  });
}

export function ExportDeviceTime(params) {
  return request({
    url: '/tax_time_error/export',
    method: 'get',
    responseType: 'blob',
    params
  });
}

export function QueryFactoryList(params) {
  return request({
    url: '/tanker_factory_error/data',
    method: 'get',
    params
  });
}

export function FactoryListError(params) {
  return request({
    url: '/tanker_factory_error/add_form',
    method: 'get',
    params
  });
}

export function ExportFactory(params) {
  return request({
    url: '/tanker_factory_error/export',
    method: 'get',
    responseType: 'blob',
    params
  });
}

export function QueryImportTimeList(params) {
  return request({
    url: '/tanker_time_error/data',
    method: 'get',
    params
  });
}

export function ImportTimeError(params) {
  return request({
    url: '/tanker_time_error/add_form',
    method: 'get',
    params
  });
}

export function ExportImportTime(params) {
  return request({
    url: '/tanker_time_error/export',
    method: 'get',
    responseType: 'blob',
    params
  });
}

export function NontaxControl(params) {
  return request({
    url: '/tax_non_alarm/data',
    method: 'get',
    params
  });
}

export function ExportNonTaxControl(params) {
  return request({
    url: '/tax_non_alarm/export',
    method: 'get',
    responseType: 'blob',
    params
  });
}

export function allegedCheatAlarm(params) {
  return request({
    url: '/alleged_cheat_alarm/data',
    method: 'get',
    params
  });
}

export function ExportAllegedCheatAlarm(params) {
  return request({
    url: '/alleged_cheat_alarm/export',
    method: 'get',
    responseType: 'blob',
    params
  });
}
